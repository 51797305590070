.react-tel-input {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    position: relative;
    width: 100%;
    padding: 0px!important
  }
  .react-tel-input :disabled {
    cursor: not-allowed;
  }
  .flag {
    width: 24px;
    height: 19px;
    background-image: url("high-res.png");
    background-repeat: no-repeat;
  }
  .ad {
    background-position: -48px -28px;
  }
  .ae {
    background-position: -72px -28px;
  }
  .af {
    background-position: -96px -28px;
  }
  .ag {
    background-position: -120px -28px;
  }
  .ai {
    background-position: -144px -28px;
  }
  .al {
    background-position: -168px -28px;
  }
  .am {
    background-position: -192px -28px;
  }
  .an {
    background-position: -216px -28px;
  }
  .ao {
    background-position: -240px -28px;
  }
  .aq {
    background-position: -264px -28px;
  }
  .ar {
    background-position: -288px -28px;
  }
  .as {
    background-position: -312px -28px;
  }
  .at {
    background-position: -336px -28px;
  }
  .au {
    background-position: -360px -28px;
  }
  .aw {
    background-position: -384px -28px;
  }
  .ax {
    background-position: 0px -52px;
  }
  .az {
    background-position: -24px -52px;
  }
  .ba {
    background-position: -48px -52px;
  }
  .bb {
    background-position: -72px -52px;
  }
  .bd {
    background-position: -96px -52px;
  }
  .be {
    background-position: -120px -52px;
  }
  .bf {
    background-position: -144px -52px;
  }
  .bg {
    background-position: -168px -52px;
  }
  .bh {
    background-position: -192px -52px;
  }
  .bi {
    background-position: -216px -52px;
  }
  .bj {
    background-position: -240px -52px;
  }
  .bl {
    background-position: -264px -52px;
  }
  .bm {
    background-position: -288px -52px;
  }
  .bn {
    background-position: -312px -52px;
  }
  .bo {
    background-position: -336px -52px;
  }
  .br {
    background-position: -360px -52px;
  }
  .bs {
    background-position: -384px -52px;
  }
  .bt {
    background-position: 0px -76px;
  }
  .bw {
    background-position: -24px -76px;
  }
  .by {
    background-position: -48px -76px;
  }
  .bz {
    background-position: -72px -76px;
  }
  .ca {
    background-position: -96px -76px;
  }
  .cc {
    background-position: -120px -76px;
  }
  .cd {
    background-position: -144px -76px;
  }
  .cf {
    background-position: -168px -76px;
  }
  .cg {
    background-position: -192px -76px;
  }
  .ch {
    background-position: -216px -76px;
  }
  .ci {
    background-position: -240px -76px;
  }
  .ck {
    background-position: -264px -76px;
  }
  .cl {
    background-position: -288px -76px;
  }
  .cm {
    background-position: -312px -76px;
  }
  .cn {
    background-position: -336px -76px;
  }
  .co {
    background-position: -360px -76px;
  }
  .cr {
    background-position: -384px -76px;
  }
  .cu {
    background-position: 0px -100px;
  }
  .cv {
    background-position: -24px -100px;
  }
  .cw {
    background-position: -48px -100px;
  }
  .cx {
    background-position: -72px -100px;
  }
  .cy {
    background-position: -96px -100px;
  }
  .cz {
    background-position: -120px -100px;
  }
  .de {
    background-position: -144px -100px;
  }
  .dj {
    background-position: -168px -100px;
  }
  .dk {
    background-position: -192px -100px;
  }
  .dm {
    background-position: -216px -100px;
  }
  .do {
    background-position: -240px -100px;
  }
  .dz {
    background-position: -264px -100px;
  }
  .ec {
    background-position: -288px -100px;
  }
  .ee {
    background-position: -312px -100px;
  }
  .eg {
    background-position: -336px -100px;
  }
  .eh {
    background-position: -360px -100px;
  }
  .er {
    background-position: -384px -100px;
  }
  .es {
    background-position: 0px -124px;
  }
  .et {
    background-position: -24px -124px;
  }
  .eu {
    background-position: -48px -124px;
  }
  .fi {
    background-position: -72px -124px;
  }
  .fj {
    background-position: -96px -124px;
  }
  .fk {
    background-position: -120px -124px;
  }
  .fm {
    background-position: -144px -124px;
  }
  .fo {
    background-position: -168px -124px;
  }
  .fr {
    background-position: -192px -124px;
  }
  .ga {
    background-position: -216px -124px;
  }
  .gb {
    background-position: -240px -124px;
  }
  .gd {
    background-position: -264px -124px;
  }
  .ge {
    background-position: -288px -124px;
  }
  .gg {
    background-position: -312px -124px;
  }
  .gh {
    background-position: -336px -124px;
  }
  .gi {
    background-position: -360px -124px;
  }
  .gl {
    background-position: -384px -124px;
  }
  .gm {
    background-position: 0px -148px;
  }
  .gn {
    background-position: -24px -148px;
  }
  .gq {
    background-position: -48px -148px;
  }
  .gr {
    background-position: -72px -148px;
  }
  .gs {
    background-position: -96px -148px;
  }
  .gt {
    background-position: -120px -148px;
  }
  .gu {
    background-position: -144px -148px;
  }
  .gw {
    background-position: -168px -148px;
  }
  .gy {
    background-position: -192px -148px;
  }
  .hk {
    background-position: -216px -148px;
  }
  .hn {
    background-position: -240px -148px;
  }
  .hr {
    background-position: -264px -148px;
  }
  .ht {
    background-position: -288px -148px;
  }
  .hu {
    background-position: -312px -148px;
  }
  .ic {
    background-position: -336px -148px;
  }
  .id {
    background-position: -360px -148px;
  }
  .ie {
    background-position: -384px -148px;
  }
  .il {
    background-position: 0px -172px;
  }
  .im {
    background-position: -24px -172px;
  }
  .in {
    background-position: -48px -172px;
  }
  .iq {
    background-position: -72px -172px;
  }
  .ir {
    background-position: -96px -172px;
  }
  .is {
    background-position: -120px -172px;
  }
  .it {
    background-position: -144px -172px;
  }
  .je {
    background-position: -168px -172px;
  }
  .jm {
    background-position: -192px -172px;
  }
  .jo {
    background-position: -216px -172px;
  }
  .jp {
    background-position: -240px -172px;
  }
  .ke {
    background-position: -264px -172px;
  }
  .kg {
    background-position: -288px -172px;
  }
  .kh {
    background-position: -312px -172px;
  }
  .ki {
    background-position: -336px -172px;
  }
  .km {
    background-position: -360px -172px;
  }
  .kn {
    background-position: -384px -172px;
  }
  .kp {
    background-position: 0px -196px;
  }
  .kr {
    background-position: -24px -196px;
  }
  .kw {
    background-position: -48px -196px;
  }
  .ky {
    background-position: -72px -196px;
  }
  .kz {
    background-position: -96px -196px;
  }
  .la {
    background-position: -120px -196px;
  }
  .lb {
    background-position: -144px -196px;
  }
  .lc {
    background-position: -168px -196px;
  }
  .li {
    background-position: -192px -196px;
  }
  .lk {
    background-position: -216px -196px;
  }
  .lr {
    background-position: -240px -196px;
  }
  .ls {
    background-position: -264px -196px;
  }
  .lt {
    background-position: -288px -196px;
  }
  .lu {
    background-position: -312px -196px;
  }
  .lv {
    background-position: -336px -196px;
  }
  .ly {
    background-position: -360px -196px;
  }
  .ma {
    background-position: -384px -196px;
  }
  .mc {
    background-position: 0px -220px;
  }
  .md {
    background-position: -24px -220px;
  }
  .me {
    background-position: -48px -220px;
  }
  .mf {
    background-position: -72px -220px;
  }
  .mg {
    background-position: -96px -220px;
  }
  .mh {
    background-position: -120px -220px;
  }
  .mk {
    background-position: -144px -220px;
  }
  .ml {
    background-position: -168px -220px;
  }
  .mm {
    background-position: -192px -220px;
  }
  .mn {
    background-position: -216px -220px;
  }
  .mo {
    background-position: -240px -220px;
  }
  .mp {
    background-position: -264px -220px;
  }
  .mq {
    background-position: -288px -220px;
  }
  .mr {
    background-position: -312px -220px;
  }
  .ms {
    background-position: -336px -220px;
  }
  .mt {
    background-position: -360px -220px;
  }
  .mu {
    background-position: -384px -220px;
  }
  .mv {
    background-position: 0px -244px;
  }
  .mw {
    background-position: -24px -244px;
  }
  .mx {
    background-position: -48px -244px;
  }
  .my {
    background-position: -72px -244px;
  }
  .mz {
    background-position: -96px -244px;
  }
  .na {
    background-position: -120px -244px;
  }
  .nc {
    background-position: -144px -244px;
  }
  .ne {
    background-position: -168px -244px;
  }
  .nf {
    background-position: -192px -244px;
  }
  .ng {
    background-position: -216px -244px;
  }
  .ni {
    background-position: -240px -244px;
  }
  .nl {
    background-position: -264px -244px;
  }
  .no {
    background-position: -288px -244px;
  }
  .np {
    background-position: -312px -244px;
  }
  .nr {
    background-position: -336px -244px;
  }
  .nu {
    background-position: -360px -244px;
  }
  .nz {
    background-position: -384px -244px;
  }
  .om {
    background-position: 0px -268px;
  }
  .pa {
    background-position: -24px -268px;
  }
  .pe {
    background-position: -48px -268px;
  }
  .pf {
    background-position: -72px -268px;
  }
  .pg {
    background-position: -96px -268px;
  }
  .ph {
    background-position: -120px -268px;
  }
  .pk {
    background-position: -192px -268px;
  }
  .pl {
    background-position: -216px -268px;
  }
  .pn {
    background-position: -240px -268px;
  }
  .pr {
    background-position: -264px -268px;
  }
  .ps {
    background-position: -288px -268px;
  }
  .pt {
    background-position: -312px -268px;
  }
  .pw {
    background-position: -336px -268px;
  }
  .py {
    background-position: -360px -268px;
  }
  .qa {
    background-position: -384px -268px;
  }
  .ro {
    background-position: 0px -292px;
  }
  .rs {
    background-position: -24px -292px;
  }
  .ru {
    background-position: -48px -292px;
  }
  .rw {
    background-position: -72px -292px;
  }
  .sa {
    background-position: -96px -292px;
  }
  .sb {
    background-position: -120px -292px;
  }
  .sc {
    background-position: -144px -292px;
  }
  .sd {
    background-position: -168px -292px;
  }
  .se {
    background-position: -192px -292px;
  }
  .sg {
    background-position: -216px -292px;
  }
  .sh {
    background-position: -240px -292px;
  }
  .si {
    background-position: -264px -292px;
  }
  .sk {
    background-position: -288px -292px;
  }
  .sl {
    background-position: -312px -292px;
  }
  .sm {
    background-position: -336px -292px;
  }
  .sn {
    background-position: -360px -292px;
  }
  .so {
    background-position: -384px -292px;
  }
  .sr {
    background-position: 0px -316px;
  }
  .ss {
    background-position: -24px -316px;
  }
  .st {
    background-position: -48px -316px;
  }
  .sv {
    background-position: -72px -316px;
  }
  .sy {
    background-position: -96px -316px;
  }
  .sz {
    background-position: -120px -316px;
  }
  .tc {
    background-position: -144px -316px;
  }
  .td {
    background-position: -168px -316px;
  }
  .tf {
    background-position: -192px -316px;
  }
  .tg {
    background-position: -216px -316px;
  }
  .th {
    background-position: -240px -316px;
  }
  .tj {
    background-position: -264px -316px;
  }
  .tk {
    background-position: -288px -316px;
  }
  .tl {
    background-position: -312px -316px;
  }
  .tm {
    background-position: -336px -316px;
  }
  .tn {
    background-position: -360px -316px;
  }
  .to {
    background-position: -384px -316px;
  }
  .tr {
    background-position: 0px -340px;
  }
  .tt {
    background-position: -24px -340px;
  }
  .tv {
    background-position: -48px -340px;
  }
  .tw {
    background-position: -72px -340px;
  }
  .tz {
    background-position: -96px -340px;
  }
  .ua {
    background-position: -120px -340px;
  }
  .ug {
    background-position: -144px -340px;
  }
  .us {
    background-position: -168px -340px;
  }
  .uy {
    background-position: -192px -340px;
  }
  .uz {
    background-position: -216px -340px;
  }
  .va {
    background-position: -240px -340px;
  }
  .vc {
    background-position: -264px -340px;
  }
  .ve {
    background-position: -288px -340px;
  }
  .vg {
    background-position: -312px -340px;
  }
  .vi {
    background-position: -336px -340px;
  }
  .vn {
    background-position: -360px -340px;
  }
  .vu {
    background-position: -384px -340px;
  }
  .wf {
    background-position: 0px -364px;
  }
  .ws {
    background-position: -24px -364px;
  }
  .ye {
    background-position: -48px -364px;
  }
  .za {
    background-position: -96px -364px;
  }
  .zm {
    background-position: -120px -364px;
  }
  .zw {
    background-position: -144px -364px;
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .hide {
    display: none;
  }
  .v-hide {
    visibility: hidden;
  }
  