.schipt-grid{
  @apply flex justify-items-start items-center gap-5 md:w-full md:h-full flex-wrap flex-row
}

/*
.schipt-grid{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1em;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}
*/