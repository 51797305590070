.schipt-carriers .serviceCodes p{
  position: relative;
}

.schipt-carriers .serviceCodes p i{
  color: red;
  font-weight: 900;
  margin-right: 0.2rem;
  cursor: pointer;
}

.carriers-group {
  @apply m-3
}

.schipt-carriers .serviceCodes p:has(i:hover){
  color: red;
  transform: scale(1.02);
}

.schipt-carriers .addServiceCode{
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: var(--focus-color);
  outline: none;  
  font-size: 0.75rem;
  background-color: transparent;
}
.schipt-carriers .addServiceCode:focus{
  @apply outline-none;
}

.schipt-carriers .addServiceCode option:not(.placeholder){
  color: var(--text-color);
  font-size: 0.9rem;
}

.schipt-carriers .addServiceCode option.placeholder{
  @apply hidden;
}
/*
.schipt-carriers tr {
  border: var(--small-border);
  border-radius: 25px;
}
*/