.clickable{
  cursor: pointer;
}

.schipt-table-body{
  @apply  relative block max-w-full h-auto rounded-md bg-schipt-white dark:bg-schipt-dark pl-3 pr-3 
}

.schipt-table-body table {
  @apply table-auto cursor-default border-collapse overflow-y-scroll block
}

.schipt-table-body table thead {
  @apply uppercase font-montserrat text-xs tracking-wide sticky top-0 bg-schipt-white dark:bg-schipt-dark h-10 text-schipt-dark-gray dark:text-schipt-white-90 text-left z-10
}

.schipt-table-body table thead th {
  @apply  px-4
}


.schipt-table-body tbody tr{
  @apply border-b dark:border-schipt-darkest bg-schipt-white dark:bg-schipt-dark hover:bg-schipt-dark-gray hover:bg-opacity-20 hover:dark:bg-schipt-darkest 
}

.schipt-table-body td{
  @apply text-xs font-montserrat font-medium px-4 py-2
}

.schipt-table-body td.status{
  @apply font-bold
}

.schipt-table-body img {
  @apply max-w-24
}

.schipt-table-body .pages {
  @apply flex flex-row gap-2 justify-center items-center py-2
}

.schipt-table-body .pages div:not(:first-child) {
  @apply w-4
}

.schipt-table-body .pages div {
  @apply cursor-pointer hover:opacity-70 
}

.schipt-table-body .pages .active {
  @apply font-bold underline 
}

.skeleton-table {
  @apply flex flex-col gap-2;
}

.skeleton-header {
  @apply flex gap-2;
}

.skeleton-header-cell {
  @apply h-6 w-full bg-gray-600 rounded-md animate-pulse;
}

.skeleton-body {
  @apply flex flex-col gap-2;
}

.skeleton-row {
  @apply flex gap-2;
}

.skeleton-cell {
  @apply h-6 w-full bg-gray-700 rounded-md animate-pulse;
}


