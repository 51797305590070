/*PRIMARY*/
.schipt-btn.primary{
@apply bg-schipt-green shadow-md dark:bg-schipt-green font-montserrat font-medium text-schipt-white py-1 px-6 rounded-md justify-center text-xs;
} 

.schipt-btn.primary:hover{
  @apply bg-schipt-dark-green transition-colors delay-100;
}

.schipt-btn.primary.disabled{
  @apply opacity-20 pointer-events-none bg-gray-400;
}

/*SECONDARY*/
.schipt-btn.secondary{
  @apply bg-schipt-white dark:bg-schipt-dark text-xs font-montserrat font-semibold text-schipt-green py-1 px-6 border border-gray-300 hover:border-transparent rounded-md justify-center m-2 ml-0
}

.schipt-btn.secondary:hover{
  @apply bg-schipt-green text-white transition-colors delay-100; 
}

/*CANCEL PRIMARY*/
.schipt-btn.primary.cancel{
  @apply bg-red-300 text-xs font-montserrat font-semibold m-2 text-red-600 py-1 px-6 rounded-md justify-center;
}

.schipt-btn.primary.cancel:hover{
  @apply bg-red-400 text-red-700 transition-colors delay-100;
}


/*CANCEL SECONDARY*/
.schipt-btn.secondary.cancel{
  @apply bg-schipt-white dark:bg-schipt-dark text-xs font-montserrat font-semibold m-2 text-red-400 py-1 px-6 border border-gray-300 hover:border-transparent rounded-md justify-center;

}

.schipt-btn.secondary.cancel:hover{
  @apply bg-red-300 text-white transition-colors delay-100; 
}

@keyframes spin {
  0% { 
    transform: rotate(0deg);

  }
  20%{
    opacity: 50%;
  }
  100% { 
    transform: scale(.7); 
  }
}

.btn[disabled] {
  animation: spin 1s linear;
  transform: scale(.7); 
  opacity: 50%;
}

.button-container{
  @apply relative inline-block
}
/*Styling used for dropdown when hovering over a button*/
.button-hover {
  @apply hidden absolute z-10 bg-schipt-white dark:bg-schipt-darkest font-montserrat text-sm font-medium shadow-md px-1 pt-1 w-max
}
.button-container:hover .button-hover {
  @apply block
}

.button-hover .document {
@apply flex flex-row w-full pb-1
}

.button-hover .print {
  @apply flex flex-row items-center w-full
}

.button-hover .preview {
@apply ml-auto px-2 flex items-center justify-center border-l-4 border-schipt-white dark:border-schipt-dark-gray
}