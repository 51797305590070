.schipt-accordion{
  @apply rounded-md drop-shadow-md bg-schipt-white dark:bg-schipt-dark
}

.schipt-accordion > .header{
  @apply bg-schipt-white dark:bg-schipt-dark font-montserrat uppercase text-schipt-black dark:text-schipt-white-90 tracking-wide font-bold text-sm p-3 rounded-md 
}

.schipt-accordion > .header label{
  @apply pointer-events-none select-none touch-none
}

.schipt-accordion > .header i{
  @apply absolute right-2 top-2 text-sm inline-block select-none duration-300
}

.schipt-accordion > .content{
  @apply hidden py-3 px-5
}

.schipt-accordion.open > .header {
  @apply border-b rounded-b-none
}

.schipt-accordion.open > .header i{
  box-shadow: none;
  transform:  rotate(180deg);
}
.schipt-accordion.open > .content,
.schipt-accordion:focus-within{
  display: block;
}

/* Visa fel i stängd accordion innehållande input-fel */
.schipt-accordion:not(.open):has(.error){ 
  transition-duration: var(--dur);
  outline: none;
  color: var(--text-color);
  border: 1px solid var(--error-color);
  box-shadow: 1px 1px 5px var(--error-color);
}