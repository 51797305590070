/*
.schipt-centralizer{
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;

  display: flex;
  align-items: center;
  justify-content: center;
}
*/
.schipt-centralizer {
  @apply m-0 p-0 border-none shadow-none flex items-center justify-center
}