.schipt-swap{
  padding: 0;
  margin: 0;
}

.schipt-swap > *{
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}