
.schipt-navbar {
  @apply  flex pl-4 pr-4 lg:pl-16 text-sm lg:pr-16 justify-between z-10 text-schipt-dark-gray dark:text-schipt-white-90 font-montserrat font-semibold w-screen flex-row bg-schipt-white dark:bg-schipt-dark items-center h-14
}

.schipt-navbar .schipt-logo {
  @apply h-8
}

.schipt-navbar .center{
  @apply flex-1
}

.schipt-navbar .left {
  @apply justify-start
}
.schipt-navbar .center {
  @apply  lg:inline-flex justify-center hidden
}
.schipt-navbar .right {
  @apply justify-end lg:inline-flex hidden
}

.schipt-navbar .schipt-navbar-item,
.schipt-navbar li {
  @apply relative z-10 tracking-widest cursor-pointer
}

.schipt-navbar .schipt-navbar-item>div {
  @apply hidden
}

.schipt-navbar .schipt-navbar-item.active>div {
  @apply absolute block right-0 left-0 mt-1.5 text-xs bg-schipt-white dark:bg-schipt-dark drop-shadow-md w-52 rounded-b-md
}

.schipt-navbar .center li, .schipt-navbar .right li {
  @apply hover:bg-schipt-dark-gray dark:hover:bg-schipt-darkest hover:bg-opacity-10 dark:hover:opacity-100 p-2 rounded-md
}

.schipt-navbar .schipt-navbar-item div>*:hover {
  @apply bg-schipt-dark-gray dark:hover:bg-schipt-darkest dark:hover:opacity-100 bg-opacity-10
}

.schipt-navbar .schipt-navbar-item div>.flag {
  @apply scale-90
}

.schipt-navbar .schipt-navbar-item div>.flag:hover {
  @apply scale-100
}

.schipt-navbar .schipt-navbar-item div>a {
  @apply block p-4
}



/*MOBILE*/
.schipt-navbar .mobile
{
  @apply block lg:hidden relative z-10 select-none
}

.schipt-navbar .mobile input {
  @apply block w-10 h-8 absolute cursor-pointer opacity-0 z-10 touch-none
}

.schipt-navbar .mobile .menu p {
  @apply pointer-events-none font-medium text-schipt-black dark:text-schipt-white-60
}

/*
 * Just a quick hamburger
 */

.schipt-navbar .mobile span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

.schipt-navbar .mobile span:first-child {
  transform-origin: 0% 0%;
}

.schipt-navbar .mobile span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
.schipt-navbar .mobile input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
}

/*
  * But let's hide the middle one.
  */
.schipt-navbar .mobile input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
.schipt-navbar .mobile input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}


.schipt-navbar .mobile .menu
{
  @apply absolute w-80 h-screen mt-5 mr-0 mb-0 -ml-64 bg-schipt-white dark:bg-schipt-dark text-schipt-dark-gray dark:text-schipt-white-90 drop-shadow-md list-none origin-right text-sm antialiased translate-x-full transition
}

.schipt-navbar .mobile li {
  @apply p-2
}

/*
 * And let's slide it in from the left
 */
 
.schipt-navbar .mobile input:checked~ul {
  transform: none;
}