.schipt-stepper .header {
  @apply flex 
}

.schipt-stepper .header .steps{
  @apply flex-row flex-nowrap w-full justify-center
}


.schipt-stepper .header .step{
  @apply flex font-montserrat text-sm
}

.schipt-stepper .header .step.active{
  @apply  text-schipt-green
}

.schipt-stepper .header .step.active .stepnumber span{
  @apply bg-schipt-green text-schipt-white border-schipt-dark-green/20
}


.schipt-stepper .header .step .stepnumber{
  @apply mr-2
}

/*
.schipt-stepper .header .steps{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-items: baseline;
}

.schipt-stepper .header .step{

  margin: 0rem 1rem;
  text-align: center;
  max-width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-wrap: nowrap;
 }

.schipt-stepper .header .step label{
  word-wrap: break-word;
  color: var(--heading-color);
 }

.schipt-stepper .steps:after{
  content: "";
  position: absolute;
  top: 24px;
  background-color: var(--heading-color);
  width: 100%;
  height: 2px;  
  z-index: -10;  
}

.schipt-stepper .stepnumber{
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: var(--heading-color);
  color: var(--accordion-text-color);
  font-size: 1.2rem;
  font-weight: 600;
  width: 2.85rem;
  padding: 0.5rem;
  border-radius: 50px;  
}


.schipt-stepper .step label,
.schipt-stepper .step > div{
  cursor: not-allowed;
}

.schipt-stepper .step.passed label,
.schipt-stepper .step.passed > div,
.schipt-stepper .step.active label,
.schipt-stepper .step.active > div{
  cursor: pointer;
}

.schipt-stepper .step.active .stepnumber{
  background-color: var(--background-color);
  border: 2px solid var(--focus-color);
  color: var(--focus-color);
}
.schipt-stepper .step.active label{
  color: var(--focus-color);
}

.schipt-stepper .footer{
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}
*/