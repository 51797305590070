.schipt-deliverytypes .serviceCodes p{
  @apply relative font-montserrat text-xs font-semibold
}

.schipt-deliverytypes .serviceCodes p i{
  @apply text-red-600 mr-2 font-bold cursor-pointer
}

.schipt-deliverytypes .serviceCodes p:has(i:hover){
  @apply text-red-600 scale-105
}

.schipt-deliverytypes .addServiceCode{
  @apply cursor-pointer appearance-none indent-px text-schipt-green outline-none  w-full text-xs border-none font-montserrat font-semibold bg-transparent
}

.schipt-deliverytypes .addServiceCode:focus{
  @apply outline-none
}

.schipt-deliverytypes .addServiceCode option:not(.placeholder){
  color: var(--text-color);
  font-size: 0.9rem;
}

.schipt-deliverytypes .addServiceCode option.placeholder{
  @apply hidden
}

.schipt-deliverytypes tr {
  border-bottom: var(--small-border);
}

.schipt-deliverytypes tr td{
  vertical-align: top;
  padding-top: 2rem;
  padding-bottom: 1rem;
}