@keyframes spin {
  0% { 
    transform: rotate(0deg);

  }
  20%{
    opacity: 50%;
  }
  100% { 
    transform: scale(.7); 
  }
}

.schipt-form.submitting .schipt-btn{
  @apply opacity-20 pointer-events-none bg-gray-400;
  animation: spin 1s linear;
  transform: scale(.7); 
  opacity: 50%;
}