/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/*White THEME*/
:root{

    /*Shadows*/
    --box-shadow: 5px 5px 10px hsl(0, 0%, 80%); 
    --full-box-shadow: 3px 3px 5px hsl(0, 0%, 80%)
        , -3px -3px 5px hsl(0, 0%, 80%);
    --small-box-shadow: 2px 2px 4px hsl(0, 0%, 50%);
    
    /*Main*/
    --heading-color: hsl(0, 0%, 50%);
    --background-color: hsl(0, 0%, 100%); 
    --text-color: hsl(0, 0%, 0%); 
    --small-border: 0.1em hsl(0, 0%, 80%) solid; 
    --focus-color: hsl(120, 40%, 65%);
    --error-color: hsl(0, 40%, 65%);
    --placeholder-color: hsl(0, 0%, 60%);
    --scrollbar-color: hsl(0, 0%, 97%);
    --scrollbar-hover-color: hsl(120, 70%, 95%);
    
    /*Tables*/
    --even-row-color: hsl(0, 0%, 95%); 
    --row-hover-color: hsla(110, 50%, 70%, 30%);

    --accordion-color: hsl(0, 0%, 30%);
    --accordion-text-color: hsl(0, 0%, 90%);
    --accordion-open-color: hsl(0, 0%, 50%);

}
/*DARK THEME*/
:root.dark{
    /*Shadows*/
    --box-shadow: 5px 5px 10px hsl(0, 0%, 0%);
    --full-box-shadow: 3px 3px 5px hsl(0, 0%, 0%)
        , -3px -3px 5px hsl(0, 0%, 0%);
    --small-box-shadow: 2px 2px 4px hsl(0, 0%, 20%);

    /*Main*/
    --heading-color: hsl(0, 0%, 50%);
    --background-color: hsl(0, 0%, 20%);
    --text-color: hsl(0, 0%, 100%);
    --even-row-color: hsl(0, 0%, 30%);
    --small-border: 0.1em hsl(0, 0%, 30%) solid; 
    --focus-color: hsl(120, 50%, 50%);
    --placeholder-color: hsl(0, 0%, 70%);
    --scrollbar-color: hsl(0, 0%, 30%);
    --scrollbar-hover-color: hsl(120, 70%, 95%);

    /*Table*/
    --row-hover-color: hsla(120, 50%, 50%, 30%);
    --submit-button-background: linear-gradient(90deg, hsl(120, 87%, 21%) 0%, hsl(120, 70%, 46%) 55%, hsl(84, 100%, 50%) 100%);

    --accordion-color: hsl(0, 0%, 30%);
    --accordion-text-color: hsl(0, 0%, 90%);
    --accordion-open-color: hsl(0, 0%, 50%);
}

/*Gray theme*/
:root.gray{
    /*Shadows*/
    --box-shadow: 5px 5px 10px hsl(0, 0%, 0%);
    --full-box-shadow: 3px 3px 5px hsl(0, 0%, 0%)
        , -3px -3px 5px hsl(0, 0%, 0%);
    --small-box-shadow: 2px 2px 4px hsl(0, 0%, 20%);

    /*Main*/
    --heading-color: hsl(0, 0%, 50%);
    --background-color: hsl(220, 10%, 30%);
    --text-color: hsl(220, 50%, 90%);
    --even-row-color: hsl(220, 15%, 40%);
    --small-border: 0.1em hsl(220, 50%, 15%) solid; 
    --focus-color: hsl(220, 50%, 70%);
    --placeholder-color: hsl(0, 0%, 70%);
    --scrollbar-color: hsl(220, 20%, 40%);
    --scrollbar-hover-color: hsl(220, 50%, 60%);

    /*Table*/
    --row-hover-color: hsla(220, 50%, 60%, 60%);
    --submit-button-background: linear-gradient(90deg, hsl(220, 87%, 21%) 0%, hsl(220, 70%, 46%) 55%, hsl(200, 100%, 50%) 100%);

    --accordion-color: hsl(220, 10%, 50%);
    --accordion-text-color: hsl(0, 0%, 90%);
    --accordion-open-color: hsl(220, 25%, 50%);
}


::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px hsla(0, 0%, 0%, 95%); 
    border-radius: 4px;
    background-color: inherit
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 4px hsla(0, 0%, 0%, 95%); 
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-hover-color)
}

html {
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
}

body{
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100%;
}

#root{
    @apply h-full bg-schipt-light-gray dark:bg-schipt-darkest z-0 relative overflow-y-scroll
}

select option{
    background-color: var(--background-color);
}

body:has(.skeleton-mount) .schipt-i :is(input,select, datalist) {    
    @apply bg-gray-700 animate-pulse;
}