*:focus {
  outline: none;
}

.schipt-input-table{  
  @apply bg-schipt-white dark:bg-schipt-dark border-collapse table-auto w-full relative block max-w-full max-h-full overflow-x-auto cursor-default rounded-md
}

.schipt-input-table th{
  @apply p-1 tracking-wider text-left font-montserrat text-schipt-dark-gray dark:text-schipt-white-90 dark:bg-schipt-dark text-xs font-semibold pointer-events-none select-none
}

  .schipt-input-table th.req{
    @apply after:content-['*'] after:text-schipt-green after:pl-1
  }

.schipt-input-table td > input, .schipt-input-table td > select{
  @apply border-schipt-dark-gray w-full text-sm pl-1 py-1 border-solid border rounded-sm bg-white dark:bg-schipt-dark font-montserrat focus:shadow-3xl focus:shadow-schipt-dark-gray
}

.schipt-input-table td > input.error,
.schipt-input-table td > select.error {
  transition-duration: var(--dur);
  outline: none;
  color: var(--text-color);
  border: 2px solid var(--error-color);
  box-shadow: 1px 1px 5px var(--error-color);
}

.schipt-input-table td > *:is(input, select){
  width: 10rem;
}
.schipt-input-table td > *:is(input[inputmode="numeric"], input[inputmode="decimal"]){
  width: 4.5rem;
}

.schipt-input-table td > select{
  width: 5rem;
}

/*
.schipt-input-table td{

  padding: 0.1rem 0.6rem;
  width: auto;  
  position: relative;

}
*/
.schipt-input-table td{
  @apply py-0.5 px-0.5 w-fit relative
}


.schipt-input-table tr{
  padding: 0;
  margin: 0;
  border: 0;
}


.schipt-input-table .func p{
  padding: 0rem;
  text-align: center;  
  align-self: center;
  align-content: center;
  color: inherit;
  font-weight: 900;
  scale: 1.7;
  font-size: 1rem;
}
.schipt-input-table .func {
  cursor: pointer;
  color: var(--text-color);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
.schipt-input-table .func:hover{
  color: var(--focus-color)
}
.schipt-input-table .func.del:hover{
  color: red
}

.schipt-input-table .adornment{
  @apply absolute right-3 top-2 text-sm pointer-events-none
}

.schipt-input-table input ~ .adornment{
  opacity: 65%;
}



/* Tooltip container */
.tooltip {
  @apply relative
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: white;
  color: #000;
  border: 0.5px solid rgba(0, 0, 0, 0.379);
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;

  text-wrap: nowrap;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: -2.1rem;
  left: 0;
}

/* Tooltip text */
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 30%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  font-size: smaller;
  visibility: visible;
}

td.shipmentGoodsDescription > input {
  @apply w-72
}