.shadowOverlay{
    justify-content: center;
    justify-items: center;
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: hsla(0, 0%, 0%, 40%);
    z-index: 100;
}

.dialogContainer{
    height: auto;
    background-color: white;
    width: clamp(22rem, 30vw, 32rem);
    position: absolute;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    top: 10px;
    border-radius: 20px; 
    padding: 0px 1rem 1rem 1rem;
    box-shadow: hsla(0, 0%, 0%, 50%) 1px 1px;   
}

.dialogContainer h1, .dialogContainer div{
    padding-left: 10px;
}

.dialogContainer h1{
    padding-top: 10px;
    font-size: 120%;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.dialogContainer .dialogMessage{
    height: 35%;
    color: hsl(0, 0%, 30%);
}

.dialogContainer h1{
    color: black;
}
.dialogButtons{
    padding-top: 10px;
    float: right;
    margin-right: 30px;
}
.dialogButtons button{    
    position: relative;
    margin-left: 10px;
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    color: black;
    background-color: inherit;
    box-shadow: none;
    font-weight: bold;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.dialogContainer button.delete{
    color: hsl(5, 100%, 60%);
    background-color: hsl(0, 100%, 92%);
}

.dialogContainer button.delete:hover{
    color: hsl(5, 100%, 53%);
    background-color: hsl(0, 100%, 85%);
}

.dialogContainer button.add{
    color: hsl(117, 84%, 40%);
    background-color: hsl(117, 100%, 90%);
}

.dialogContainer button.add:hover{
    color: hsl(117, 84%, 30%);
    background-color: hsl(117, 100%, 80%);
}

.dialogContainer button.info{
    color: hsl(200, 100%, 35%);
    background-color: hsl(200, 100%, 90%);
}

.dialogContainer button.info:hover{
    color: hsl(200, 100%, 27%);
    background-color: hsl(200, 100%, 82%);
}

.dialogContainer button.warning{
    color: hsl(39, 100%, 50%);
    background-color: hsl(39, 100%, 92%);
}

.dialogContainer button.warning:hover{
    color: hsl(39, 100%, 53%);
    background-color: hsl(39, 100%, 85%);
}

button:hover{
    background-color: hsla(0, 0%, 0%, 10%);
}

