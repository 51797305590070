

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.schipt-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
}

.schipt-dialog{
  inset: 0;
  padding: .3rem;
  border: 2px solid orange;
  border-radius:".375rem"
}

/*
.btn {
  @apply text-black font-montserrat font-medium text-sm px-6 py-1 mt-2 rounded-sm border-solid border-schipt-green border-2 hover:bg-gradient-to-r from-schipt-green to-schipt-light-green transition ease-in
}

.input-text {
  @apply border-solid border-2 border-gray-300
}
*/