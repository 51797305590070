.schipt-group {
  @apply bg-schipt-white dark:bg-schipt-dark rounded-md p-2 min-w-full
}

.schipt-group .schipt-legend-container {
  @apply mb-4
}

.schipt-group legend {
  @apply text-schipt-black dark:text-schipt-white-90 font-montserrat font-bold
}

.schipt-group .third {
  @apply w-1/3
}

.schipt-group .half {
  @apply w-1/2
}


/*
.schipt-group{
  border: var(--small-border);
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  padding: 0em;
  box-shadow: var(--box-shadow);
}

.schipt-group legend{
  margin-left: 3em;
  padding: 0em 1em;
  color: var(--heading-color);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.7rem;
  font-weight: 700;
}
*/